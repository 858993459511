import { Controller } from "@hotwired/stimulus";
import { Tooltip } from "bootstrap";

export default class extends Controller {
  connect() {
    // window.setTimeout(() => {
    //   let alertList = this.element.querySelectorAll(".alert-autohide");
    //   Array.from(alertList).forEach((element) => {
    //     let button = element.querySelector("button");
    //     if (button) {
    //       button.click();
    //     }
    //   });
    // }, 3000);
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
  }
}
