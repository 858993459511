import { Controller } from "@hotwired/stimulus";
import { Loader } from "@googlemaps/js-api-loader";

export default class extends Controller {
  static targets = ["field"];

  connect() {
    if (this.hasFieldTarget) {
      if (typeof google !== "object") {
        let loader = new Loader({
          apiKey: "AIzaSyA6FYj_bJQjGjz4_gQA-Kn-gQJEAJvjZW4",
          version: "weekly",
          libraries: ["places"],
          language: "it",
          region: "it",
        });

        loader.load().then(() => {
          this.initMap();
        });
      } else {
        this.initMap();
      }
    }
  }

  initMap() {
    this.autocomplete = new google.maps.places.Autocomplete(this.fieldTarget, {
      fields: ["name"],
      types: ["address"],
      componentRestrictions: { country: "it" },
    });
    this.autocomplete.setFields(["name"]);
    this.autocomplete.addListener(
      "place_changed",
      this.placeChanged.bind(this)
    );
  }

  placeChanged() {
    console.log("changed");
    // let place = this.autocomplete.getPlace();
  }

  keydown(event) {
    if (event.key == "Enter") {
      event.preventDefault();
    }
  }
}
