import { Controller } from "@hotwired/stimulus";
import SignaturePad from "signature_pad";

export default class extends Controller {
  static targets = [
    "signatureField",
    "signatureCanvas",
    "signatureClear",
    "signatureUndo",
  ];

  connect() {
    this.signaturePad = new SignaturePad(this.signatureCanvasTarget, {
      backgroundColor: "rgb(255, 255, 255)",
    });
    window.addEventListener("resize", () => {
      this.resizeCanvas();
    });
    window.addEventListener("orientationchange", () => {
      this.resizeCanvas();
    });
    this.resizeCanvas();

    // this.signaturePad.fromDataURL(this.signatureFieldTarget.value);

    if (this.hasSignatureClearTarget) {
      this.signatureClearTarget.addEventListener("click", (el) => {
        el.preventDefault();
        if (confirm("ATTENZIONE: Eliminare la firma?")) {
          this.signaturePad.clear();
          this.signatureFieldTarget.value = "";
        }
      });
    }

    if (this.hasSignatureUndoTarget) {
      this.signatureUndoTarget.addEventListener("click", (el) => {
        el.preventDefault();
        var data = this.signaturePad.toData();

        if (data) {
          data.pop(); // remove the last dot or line
          this.signaturePad.fromData(data);
        }
      });
    }

    this.parent_form = this.signatureCanvasTarget.closest("form");
    this.parent_form.onsubmit = (e) => {
      this.signatureFieldTarget.value = this.signaturePad.toDataURL();
    };
  }

  resizeCanvas() {
    if (!this.signaturePad.isEmpty()) {
      this.signatureFieldTarget.value = this.signaturePad.toDataURL();
    }

    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    this.signatureCanvasTarget.width =
      this.signatureCanvasTarget.offsetWidth * ratio;
    this.signatureCanvasTarget.height =
      this.signatureCanvasTarget.offsetHeight * ratio;
    this.signatureCanvasTarget.getContext("2d").scale(ratio, ratio);
    this.signaturePad.clear(); // otherwise isEmpty() might return incorrect value

    this.signaturePad.fromDataURL(this.signatureFieldTarget.value);
  }
}
