import { Controller } from "@hotwired/stimulus";
import { Loader } from "@googlemaps/js-api-loader";

export default class extends Controller {
  static targets = ["mapContainer"];

  connect() {
    if (this.hasMapContainerTarget) {
      if (typeof google !== "object") {
        let loader = new Loader({
          apiKey: "AIzaSyA6FYj_bJQjGjz4_gQA-Kn-gQJEAJvjZW4",
          version: "weekly",
          libraries: ["places"],
          language: "it",
          region: "it",
        });

        loader.load().then(() => {
          this.initMap();
        });
      } else {
        this.initMap();
      }
    }
  }

  initMap() {
    let map = new google.maps.Map(this.mapContainerTarget, {
      center: { lat: 45.46406575, lng: 9.190662109391159 },
      zoom: 22,
      mapId: "9ec6d1636e929263",
    });

    this.paths = [];

    const lineSymbol = {
      path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
    };
    const circleSymbol = {
      path: google.maps.SymbolPath.CIRCLE,
    };

    let paths = this.mapContainerTarget.dataset.paths;
    if (paths) {
      var bounds = new google.maps.LatLngBounds();
      JSON.parse(paths).forEach((mapPath) => {
        let line = new google.maps.Polyline({
          path: [
            { lat: parseFloat(mapPath[0][0]), lng: parseFloat(mapPath[0][1]) },
            { lat: parseFloat(mapPath[1][0]), lng: parseFloat(mapPath[1][1]) },
          ],
          strokeColor: mapPath[2],
          strokeOpacity: 0.5,
          strokeWeight: 2,
          icons: [
            {
              icon: lineSymbol,
              offset: "100%",
            },
            {
              icon: circleSymbol,
              offset: "0%",
            },
          ],
          map: map,
        });

        let fromMarker = new google.maps.Marker({
          position: {
            lat: parseFloat(mapPath[0][0]),
            lng: parseFloat(mapPath[0][1]),
          },
          map: map,
          label: {
            text: "A",
          },
        });

        let toMarker = new google.maps.Marker({
          position: {
            lat: parseFloat(mapPath[1][0]),
            lng: parseFloat(mapPath[1][1]),
          },
          map: map,
          label: {
            text: "B",
          },
        });

        this.paths.push([mapPath[3], line, fromMarker, toMarker]);
        bounds.extend({
          lat: parseFloat(mapPath[0][0]),
          lng: parseFloat(mapPath[0][1]),
        });
        bounds.extend({
          lat: parseFloat(mapPath[1][0]),
          lng: parseFloat(mapPath[1][1]),
        });

        // new google.maps.Marker({
        //   position: {
        //     lat: parseFloat(mapPath[0][0]),
        //     lng: parseFloat(mapPath[0][1]),
        //   },
        //   // label: mapPath[3],
        //   map: map,
        //   icon: {
        //     path: google.maps.SymbolPath.CIRCLE,
        //     strokeColor: mapPath[2],
        //     strokeOpacity: 0,
        //     fillColor: mapPath[2],
        //     fillOpacity: 0.9,
        //     scale: 10,
        //   },
        //   label: {
        //     color: "#000",
        //     fontWeight: "normal",
        //     text: mapPath[3],
        //     fontSize: "10px",
        //   },
        // });
      });
      map.fitBounds(bounds, {
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
      });
    }
  }

  hideAll() {
    this.paths.forEach((path) => {
      path[1].setVisible(false);
      path[2].setVisible(false);
      path[3].setVisible(false);
    });
  }

  showAll() {
    this.paths.forEach((path) => {
      path[1].setVisible(true);
      path[2].setVisible(true);
      path[3].setVisible(true);
    });
  }

  showHidePath(pathId, value) {
    const row = this.paths.findIndex((row) => row[0] == pathId);
    if (row != null && row != -1) {
      this.paths[row][1].setVisible(value);
      this.paths[row][2].setVisible(value);
      this.paths[row][3].setVisible(value);
    }
  }
}
