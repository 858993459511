import { Controller } from "@hotwired/stimulus";
import { get, post } from "@rails/request.js";

export default class extends Controller {
  static targets = [
    "customer",
    "fromCompany",
    "fromFirstName",
    "fromLastName",
    "fromPhone",
    "fromEmail",
    "fromCc",
    "fromAddress",
    "toCompany",
    "toName",
    "toPhone",
    "toEmail",
    "toAddress",
  ];
  static values = {
    customerDetailUrl: String,
  };

  connect() {}

  async updateCustomer(event) {
    let customerId = this.customerTarget.selectedOptions[0].value;
    if (customerId != "") {
      const response = await get(
        `${this.customerDetailUrlValue}/${this.customerTarget.selectedOptions[0].value}.json`,
        {
          responseKind: "json",
        }
      );
      let json = await response.json;
      if (json) {
        console.log(json);
        this.fromCompanyTarget.value = json.company_name;
        this.fromFirstNameTarget.value = json.first_name;
        this.fromLastNameTarget.value = json.last_name;
        this.fromPhoneTarget.value = json.phone;
        this.fromEmailTarget.value = json.email;
        this.fromAddressTarget.value = json.address;
      }
    }
  }

  copyToDestination(event) {
    event.preventDefault();
    this.toCompanyTarget.value = this.fromCompanyTarget.value;
    this.toNameTarget.value =
      this.fromFirstNameTarget.value + " " + this.fromLastNameTarget.value;
    this.toPhoneTarget.value = this.fromPhoneTarget.value;
    this.toEmailTarget.value = this.fromEmailTarget.value;
    this.toAddressTarget.value = this.fromAddressTarget.value;
  }
}
